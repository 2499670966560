import React from "react";
import CTAForm from "./CTA-form";

const CTABlock = () => {
    return (
        <div className={"w-full block mt-20"}>
            <div className={"w-full flex justify-center"}>
                <h2 className={"h2"}>Eerder op de hoogte zijn?</h2>
            </div>

            <div className={"w-full flex justify-center"}>
                <CTAForm />
            </div>
        </div>
    )
}

export default CTABlock;