import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import * as dayjs from "dayjs";
import 'dayjs/locale/nl';
import _ from "lodash";
import {useMediaQuery} from "react-responsive";
import Seo from "../components/seo";
import CTABlock from "../components/CTA-block";

const AgendaPage = ({data: { upcomingEvents, pastEventsQuery } }) => {
    const pastEvents = _.sortBy(pastEventsQuery.pastEvents, 'startDate').reverse()

    dayjs.locale('nl');
    const isMD = useMediaQuery({
        query: '(min-width: 768px)'
    })

    return (
        <Layout>
            <Seo title={"Evenementen"} description={"Alle aankomende evenementen"} />
            <section className={"w-full bg-white pt-10 flex flex-col items-center pb-10 font-primary text-black"}>
                <div className={"jdb--menu-spacer"} />
                <div className={"pb-2"}><h1 className={"h1"}>Aankomende evenementen</h1></div>
                <div className={"flex flex-col w-5/6 lg:w-2/3 mt-10 divide-y divide-dashed divide-gray-700 space-y-3"}>
                    {upcomingEvents.edges.length === 0 ? (
                        <div className={""}>
                            <div className={"flex justify-center"}>
                                <p>Er zijn op dit moment geen aankomende evenementen. Houd deze pagina in de gaten voor nieuwe evenementen!</p>
                            </div>
                            <CTABlock />
                        </div>
                    ) : ''}

                    {upcomingEvents.edges.map((event) => {
                        const momentStartDate = dayjs(event.node.startDate);
                        return (
                            <div className={"flex flex-row w-full pt-3"} key={event.node.id}>
                                <div className={"flex flex-row w-24"}>
                                    <div className={"font-black text-6xl"}>{momentStartDate.format('DD')}</div>
                                </div>
                                <div className={"flex flex-row w-28"}>
                                    <div className={"flex flex-col justify-center font-secondary mt-4"}>
                                        <div>{momentStartDate.format('MMMM')}</div>
                                        <div>{momentStartDate.format('dddd')}</div>
                                    </div>
                                </div>
                                <div className={"font-semibold font-secondary text-xl flex items-center mt-4"}>
                                    {event.node.title}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>

            <section className={"w-full bg-black flex flex-row bg-no-repeat"}>
                <div className={"w-full flex flex-col justify-center items-center pb-10 pt-10 bg-jan-9 bg-cover bg-center bg-no-repeat bg-fixed min-h-[300px]"}>
                    <div className={""}><h1 className={"text-white h1 mb-2"}>Afgelopen evenementen</h1></div>
                </div>
            </section>
            <section className={"w-full bg-white pt-10 flex flex-col items-center pb-10 font-primary text-black"}>
                <div className={"font-secondary text-red-600 text-2xl uppercase"}></div>
                <div className={"flex flex-col w-5/6 lg:w-2/3 mt-10 divide-y divide-dashed divide-gray-700 space-y-3"}>
                    {pastEvents.map((event) => {
                        const momentStartDate = dayjs(event.startDate);
                        return (
                            <div className={"flex flex-row w-full pt-3"} key={event.postId}>
                                <div className={"flex flex-row w-28 md:w-24"}>
                                    <div className={"font-black text-4xl md:text-6xl"}>{momentStartDate.format('DD')}</div>
                                </div>
                                <div className={"flex flex-row w-20 md:w-28"}>
                                    <div className={"flex flex-col justify-center font-secondary mt-4"}>
                                        <div>{momentStartDate.format(!isMD ? 'MMM' : 'MMMM')}</div>
                                        <div>{momentStartDate.format(!isMD ? 'ddd' : 'dddd')}</div>
                                    </div>
                                </div>
                                <div className={"font-semibold font-secondary text-xl flex items-center mt-4 md:w-30"}>
                                    {event.postTitle}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        </Layout>
    )
};

export default AgendaPage;


export const pageQuery = graphql`
query AgendaQuery($currentDate: Date!) {
     upcomingEvents: allWpEvent(sort: {order: ASC, fields: startDate}, filter: {date: {gte: $currentDate}}) {
        edges {
          node {
            title
            startDate
            endDate
            id
          }
        }
     }
     pastEventsQuery: jdb {
        pastEvents {
            startDate
            title
            postTitle
            postId
        }
    }
}
`
