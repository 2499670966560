import React from "react";
import { useForm, ValidationError } from "@formspree/react";

const CTAForm = () => {
    const [state, handleSubmit] = useForm("xeqdvbyj");

    if (state.succeeded) {
        return (
            <div className={"w-full pt-10 font-secondary text-center"}>
                Bedankt! Je ontvangt als eerste een bericht zodra er weer een optreden is.
            </div>
        )
    }

    return (
        <form className="w-2/3 flex pt-5 items-baseline font-secondary" onSubmit={handleSubmit}>
                <input
                    className="appearance-none flex-1 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email" type="email" name={"email"} required={true} placeholder={"E-mailadres"}/>
                <button
                    className="ml-5 jdb--button-blue-alternate focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="submit" disabled={state.submitting}>
                    Versturen
                </button>

                <ValidationError errors={state.errors} />
        </form>
    )
}

export default CTAForm;